import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AppTracker {
  private get _paq() {
    return (window as any)._paq;
  }

  public canTrack(): boolean {
    return !( typeof this._paq === 'undefined' || this._paq === null );
  }

  public setUserId(userId: string ): void {
    if(this.canTrack()) {
      this._paq.push(['setUserId', userId]);
    }
  }

  public trackEvent(category: string, action: string, name: string = null, value: string = null, dimensions: any = null): void {
    if ( this.canTrack() ) {

        if(dimensions) {
            this._paq.push(['trackEvent', category, action, name, value, dimensions]);
        } else
        if (value) {
          this._paq.push(['trackEvent', category, action, name, value]);
        } else
        if (name) {
          this._paq.push(['trackEvent', category, action, name]);
        } else {
          this._paq.push(['trackEvent', category, action]);
        }
    }
  }

  public setCustomDimensionValue(id: number, value: string): void {
    if (this.canTrack()) {
        this._paq.push(['setCustomDimensionValue', id, value]);
    }
  }
  
  public deleteCustomDimension(id: number): void {
     if (this.canTrack()) {
          this._paq.push(['deleteCustomDimension', id]);
     }
  }

  public trackPageView(customPageTitle: string, dimensions: any = null): void {
    if (this.canTrack()) {
      if(dimensions) {
        this._paq.push(['trackPageView', customPageTitle, dimensions]);
      } else {			
        this._paq.push(['trackPageView', customPageTitle]);
      }
    }
  }
}

import { Injectable } from '@angular/core';

import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class MessageHelperService {

  private readonly DEFAULT_LIFE = 10000;

  constructor(private messageService: MessageService) { }

  showError(detail: string): void {
    this.messageService.add({
      closable: true,
      severity: 'error',
      summary: '',
      detail,
      life: this.DEFAULT_LIFE,
    });
  }

  showInfo(detail: string): void {
    this.messageService.add({
      closable: true,
      severity: 'info',
      summary: '',
      detail,
      life: this.DEFAULT_LIFE
    });
  }

  showSuccess(detail: string): void {
    this.messageService.add({
      closable: true,
      severity: 'success',
      summary: '',
      detail,
      life: this.DEFAULT_LIFE
    });
  }

  showWarning(detail: string): void {
    this.messageService.add({
      closable: true,
      severity: 'warn',
      summary: '',
      detail,
      life: this.DEFAULT_LIFE
    });
  }

  showAnnouncement(detail: string, icon: string, data: any = {}): void {
    this.messageService.add({
      closable: true,
      severity: '',
      summary: '',
      icon,
      detail,
      styleClass: 'p-toast-announcement',
      data: data,
      sticky: true,
    });
  }

  showSurvey(icon: string, summary: string, detail: string, data: any = {}): void {
    this.messageService.add({
      closable: true,
      severity: '',
      icon,
      summary,
      detail,
      styleClass: 'p-toast-survey',
      data: data,
      sticky: true,
    });
  }

  clear(): void {
    this.messageService.clear();
  }
}

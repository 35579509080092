import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalService } from '@app/one-core/services/local.service';
import { OneHttpParams } from '@app/one-core/common/http/one-httpclient-factory';


@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private localService: LocalService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language = (request.params as OneHttpParams)?.language ? (request.params as OneHttpParams).language : this.localService.getPortalLanguage();
    const newRequest = request.clone({
      headers: request.headers.set( 'Accept-Language', language)
    });

    return next.handle(newRequest);
  }
}

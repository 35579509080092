import { Injectable, Injector } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ContextService {

  previousUrl: string;

  constructor(private injector: Injector) { }

  init() {
    const router = this.injector.get(Router);

    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }
}

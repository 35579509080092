export class UiLanguage {
  label: string;
  value: string;
  enabled?: UiLanguageEnabledType;
  order?: number;
}

export enum UiLanguageEnabledType {
  None = 0,
  TecDocLanguage = 1 << 0,
  PortalLanguage = 1 << 1,
}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { environment } from '@env/environment';

import { HttpBaseResponse } from '@app/one-core/common/http/http-base-response';
import { OneHttpClient } from '@app/one-core/common/http/one-httpclient-factory';

import { ArticleOverviewSection } from '@app/one-models/models/article-overview-section';
import { UserTenant } from '@app/one-models/models/user-tenant';
import { TenantSettings } from '@app/one-models/models/tenant-settings';


@Injectable({
  providedIn: 'root'
})
export class PortalSettingService {
  private api = environment.services.portalApi;
  private configurationSectionEndpoint = '/settings/sections';
  private tenantEndpoint = '/tenant';

  private sectionsOrder$: Observable<ArticleOverviewSection[]>;

  constructor(
    private http: OneHttpClient) { }

  getSectionsOrder(): Observable<ArticleOverviewSection[]> {
    if (!this.sectionsOrder$) {
      const url = `${this.api}${this.configurationSectionEndpoint}`;
      this.sectionsOrder$ = this.http.get<HttpBaseResponse<ArticleOverviewSection[]>>(url).pipe(
        map(response => response.data),
        shareReplay(1)
      );
    }

    return this.sectionsOrder$;
  }

  getSectionsOrderForMaintenance(): Observable<ArticleOverviewSection[]> {    
    const url = `${this.api}${this.configurationSectionEndpoint}/maintenance`;
    return this.http.get<HttpBaseResponse<ArticleOverviewSection[]>>(url).pipe(
      map(response => response.data));
  }

  saveSectionsOrder(sectionsOrder: ArticleOverviewSection[]): Observable<HttpBaseResponse<ArticleOverviewSection[]>> {
    const url = `${this.api}${this.configurationSectionEndpoint}/maintenance`;
    return this.http.put<HttpBaseResponse<ArticleOverviewSection[]>>(url, sectionsOrder).pipe(
      map(response => {
        this.sectionsOrder$ = null;
        return response;
      }),
    );
  }

  getTenantSettings(): Observable<TenantSettings> {
    const url = `${this.api}${this.tenantEndpoint}/settings`;
    return this.http.get<HttpBaseResponse<TenantSettings>>(url).pipe(
      map(response => response.data),
      shareReplay(1),
    );
  }

  getTenants(): Observable<UserTenant[]> {
    const url = `${this.api}/user/tenants`;
    return this.http.get<HttpBaseResponse<UserTenant[]>>(url).pipe(
      map(response => response.data),
      shareReplay(1),
    );    
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export class ObjectHelper {
  /**
   * Checks if two objects are equal by comparing their JSON string representations.
   * @param object1 The first object to compare.
   * @param object2 The second object to compare.
   * @returns true if both objects are equal, otherwise false.
   */
  static equals(object1, object2): boolean {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  /**
   * Checks if an object contains a specific property.
   * @param object The object to check.
   * @param property The property name to check for.
   * @returns true if the property exists in the object, otherwise false.
   */
  static has(object, property): boolean {
    return property in object;
  }

  /**
   * Checks if a value is considered "empty". Empty can be:
   * - An empty string, null, or undefined.
   * - An array with a single empty string element.
   * @param obj The value to check.
   * @returns true if the value is considered empty, otherwise false.
   */
  static isEmpty(obj: any): boolean {
    return obj === '' || obj === null || obj === undefined || (Array.isArray(obj) && obj.length === 1 && obj[0] === '');
  }

  /**
   * Checks if an object has no properties.
   * @param obj The object to check.
   * @returns true if the object is empty, otherwise false.
   */
  static isEmptyObj(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  /**
   * Creates a deep clone of an object.
   * @param object The object to clone.
   * @returns A deep clone of the object.
   */
  static clone<T>(object: T): T {
    const json = JSON.stringify(object);
    return JSON.parse(json);
  }

  /**
   * Generate unique IDs for use as pseudo-private/protected names.
   * Similar in concept to <http://wiki.ecmascript.org/doku.php?id=strawman:names>.
   *
   * The goals of this function are twofold:
   *  * Provide a way to generate a string guaranteed to be unique when compared
   *    to other strings generated by this function.
   *
   *  * Make the string complex enough that it is highly unlikely to be
   *    accidentally duplicated by hand (this is key if you're using `ID`
   *    as a private/protected name on an object).
   */
  static uniqid(): string {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 10);
  }  
}

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import languages from '../../../assets/catalog/languages.json';
import { UiLanguage, UiLanguageEnabledType } from '@app/one-models/models/ui-language';
import { CookieKey } from '@app/one-models/models/cookies/cookie-keys';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  public DEFAULT_LANGUAGE = 'en';

  constructor(private cookieService: CookieService) { }

  getPortalLanguages(): UiLanguage[] {
    return (languages as UiLanguage[])
        .filter((l) => ((l.enabled ?? UiLanguageEnabledType.None) & UiLanguageEnabledType.PortalLanguage) === UiLanguageEnabledType.PortalLanguage)
        .sort((a, b) => a.order > b.order ? 1 : -1);
  }

  getTecDocLanguages(): UiLanguage[] {
    return (languages as UiLanguage[])
        .filter((l) => ((l.enabled ?? UiLanguageEnabledType.None) & UiLanguageEnabledType.TecDocLanguage) === UiLanguageEnabledType.TecDocLanguage)
        .sort((a, b) => a.label.localeCompare(b.label));
  }

  getPortalLanguage(): string {
    return this.validateLanguage(this.cookieService.get(CookieKey.PortalLanguage), this.getPortalLanguages());
  }

  getTecDocLanguage(): string {
    return this.validateLanguage(this.cookieService.get(CookieKey.TecDocLanguage), this.getTecDocLanguages());
  }

  setPortalLanguage(value: string): void {
    this.cookieService.set(CookieKey.PortalLanguage, this.validateLanguage(value, this.getPortalLanguages()), 3650, '/');
  }

  setTecDocLanguage(value: string): void {
    this.cookieService.set(CookieKey.TecDocLanguage, this.validateLanguage(value, this.getTecDocLanguages()), 3650, '/');
  }

  private getDefaultLanguage(languages: UiLanguage[]): string {
    return (languages ?? []).find(l => l.value === navigator.language) ? navigator.language : this.DEFAULT_LANGUAGE;
  }

  private validateLanguage(language: string, languages: UiLanguage[]): string {
    return (languages ?? []).find(l => l.value === language) ? language : this.getDefaultLanguage(languages);
  }
}

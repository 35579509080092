import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';


// export enum AppEvent {
//   BrandUpdated = 'BRAND_UPDATED',
//   UserProfileUpdated = 'USERPROFILE_UPDATED',
// }

export type BroadcastEventType = 'BrandUpdated' | 'UserProfileUpdated';

class BroadcastMessage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public event: BroadcastEventType, public payload: any) {}
}

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  private eventBrocker = new Subject<BroadcastMessage>();

  // Listen to an BroadcastEventType filtered by the event name
  on<T>(event: BroadcastEventType): Observable<T> {
    return this.eventBrocker.pipe(
      filter((request: BroadcastMessage) => request.event === event),
      map((request: BroadcastMessage) => request.payload),
    );
  }

  // Dispatch an BroadcastEventType with an optional payload
  dispatch<T>(event: BroadcastEventType, payload: T = null): void {
    this.eventBrocker.next({ event, payload });
  }
}
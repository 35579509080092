import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LocalService } from '@app/one-core/services/local.service';


@Injectable({
  providedIn: 'root'
})
export class DefaultTranslationsService {
  defaultTranslations: any;

  constructor(
    private localService: LocalService,
    private translateService: TranslateService) {}

  loadDefaultTranslations(): Promise<void> {
    return new Promise<void>((resolve) => {
      const language = this.localService.getPortalLanguage();
      if (language !== 'en') {
        this.translateService.getTranslation('en').subscribe(data => {
          this.defaultTranslations = data;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }
}

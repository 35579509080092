import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { environment } from '@env/environment';

import { CookieService } from 'ngx-cookie-service';

import { HttpBaseResponse } from '@app/one-core/common/http/http-base-response';
import { OneHttpClient, OneHttpParams } from '@app/one-core/common/http/one-httpclient-factory';
import { FeatureSettings } from '@app/one-models/models/feature-settings';
import { Role } from '@app/one-models/models/role';
import { BroadcastService } from '@app/one-core/services/broadcast.service';
import { FeaturesService } from '@app/one-core/services/features-service';
import { LocalService } from '@app/one-core/services/local.service';

import { CookieKey } from '@app/one-models/models/cookies/cookie-keys';
import { PortalSettingService } from '@app/one-core/services/portal-settings-service';
import { User } from '@app/one-models/models/user';
import { UserProfile } from '@app/one-models/models/user-profile';
import { UserTenant } from '@app/one-models/models/user-tenant';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  private api = environment.services.portalApi;
  private hubApi = environment.services.oneHubApi;
  private userEndpoint = '/user';
  private userProfileEndpoint = '/user/userprofile';
  private rolesEnpoint = '/roles';
  private _cache$: Observable<UserProfile>;
  private initBehaviorSubject = new BehaviorSubject(false);

  featuresConfiguration: { [featureName: string]: FeatureSettings };
  userProfile: UserProfile;
  userTenants: UserTenant[] = [];

  get init$(): Observable<boolean> {
    return this.initBehaviorSubject.asObservable();
  }

  constructor(
    private http: OneHttpClient,
    private broadcastService: BroadcastService,
    private featuresService: FeaturesService,
    private portalSettingsService: PortalSettingService,
    private cookieService: CookieService,
    private localService: LocalService,
  ) {}

  init(): Observable<UserProfile> {
    return this.portalSettingsService.getTenants().pipe(
      switchMap((userTenants) => {
        this.userTenants = userTenants;

        return forkJoin([this.get(), this.featuresService.getFeaturesConfiguration()]);
      }),
      map(([userProfile, featuresConfiguration]) => {
        this.userProfile = new UserProfile(userProfile);
        this.featuresConfiguration = featuresConfiguration;
        this.initBehaviorSubject.next(true);
        return this.userProfile;
      }),
    );
  }

  get(): Observable<UserProfile> {
    if (!this._cache$) {
      this._cache$ = this.requestUserProfile().pipe(
        tap((x) => this.ensureUserLanguage(x)),
        map((x) => new UserProfile(x)),
        shareReplay(1),
      );
    }

    return this._cache$;
  }

  put(userProfile: UserProfile, reloadRequired = true): Observable<any> {
    return this.http.put(`${this.api}${this.userProfileEndpoint}`, userProfile).pipe(
      tap(() => {
        if (reloadRequired) {
          this.invalidateCache();
          this.init().subscribe();
        }
      }),
    );
  }

  getAllUsers(silenceAction = false): Observable<User[]> {
    const params = new OneHttpParams(silenceAction);
    return this.http
      .get<HttpBaseResponse<User[]>>(`${this.api}${this.userEndpoint}`, { params })
      .pipe(map((response) => response.data));
  }

  saveUser(user: User, silenceAction = false): Observable<HttpBaseResponse<number>> {
    const params = new OneHttpParams(silenceAction);
    const url = `${this.hubApi}${this.userEndpoint}`;
    return this.http.put<HttpBaseResponse<number>>(url, user, { params }).pipe(
      tap(() => {
        this.invalidateCache();
        this.broadcastService.dispatch<number>('UserProfileUpdated');
        this.init().subscribe();
      }),
    );
  }

  deleteUsers(ids: number[]): Observable<HttpBaseResponse<number>> {
    const url = `${this.hubApi}${this.userEndpoint}/delete`;
    return this.http.post<HttpBaseResponse<number>>(url, ids).pipe(
      tap(() => {
        this.invalidateCache();
        this.init().subscribe();
      }),
    );
  }

  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.api}${this.rolesEnpoint}`);
  }

  hasFeature(featureName: string): boolean {
    return featureName in this.featuresConfiguration;
  }

  getTenant(): UserTenant {
    if (!this.userTenants || this.userTenants.length === 0) {
      return null;
    }

    const defaultTenant = this.userTenants[0];
    let setTenant = false;

    let dataSupplierId = +this.cookieService.get(CookieKey.UserTenant);
    if (!dataSupplierId) {
      dataSupplierId = defaultTenant.dataSupplierId;
      setTenant = true;
    }

    const userTenant = this.userTenants.find((m) => m.dataSupplierId === dataSupplierId) ?? defaultTenant;
    if (setTenant) {
      this.setTenant(userTenant.dataSupplierId);
    }

    return userTenant;
  }

  setTenant(dataSupplierId: number): void {
    this.cookieService.set(CookieKey.UserTenant, dataSupplierId.toString(), 3650, '/');
    this.invalidateCache();
  }

  resendActivationLink(userId: number): Observable<HttpBaseResponse<boolean>> {
    const params = new OneHttpParams(true);
    const url = `${this.hubApi}${this.userEndpoint}/${userId}/resend-activation-link`;
    return this.http.post<HttpBaseResponse<boolean>>(url, null, { params });
  }

  private ensureUserLanguage(up: UserProfile): void {
    const portalLanguage = up?.settings?.portalLanguage ?? this.localService.getPortalLanguage();
    const tecDocLanguage = up?.settings?.tecDocLanguage ?? this.localService.getTecDocLanguage();

    if (
      tecDocLanguage !== this.localService.getTecDocLanguage() ||
      portalLanguage !== this.localService.getPortalLanguage()
    ) {
      this.localService.setTecDocLanguage(tecDocLanguage);
      this.localService.setPortalLanguage(portalLanguage);

      window.location.reload();
    }
  }

  private invalidateCache(): void {
    this.userProfile = null;
    this._cache$ = null;
  }

  private requestUserProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(`${this.api}${this.userProfileEndpoint}`);
  }
}
